import * as React from "react";
import { keyframes } from "@stitches/react";

import { defaultColors, styled } from "../../../stitches.config";

import Link from "../../../components/Link";
import Logo from "../../atoms/Logo";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg?react";
import { ReactComponent as AccountIcon } from "../../assets/icons/myaccount.svg?react";
import AudioBrief from "./AudioBrief";
import SearchBar from "./SearchBar";
import Notifications from "./Notifications";
import NavLogo from "./NavLogo"

import { DISABLE_PREMIUM_FLAG_ACTIVE } from "../../../components/common/constants/constants"

const CATEGORIES = window.APP_SETTINGS.nav_categories;

const NavbarHidingDefault: React.FC = () => {
  const [isSearchBar, setSearchBar] = React.useState(false);
  const toggleSearchBar = () => setSearchBar((prev) => !prev);
  const isDesktop = window.innerWidth > 996;

  const mainDomain = window.APP_SETTINGS.main_domain
  const mainDomainAdress = mainDomain ? `https://${mainDomain}/` : `/`;
  const mainDomainDashboard = mainDomain ? `https://${mainDomain}/dashboard` : `/dashboard`;

  return (
    <NavWrap>
      <Link to={mainDomainAdress} data-analytics-name="nav-main-logo-hiding-default">
        <NavLogo
          className="navigation-top--logo"
          brand={window.BRAND as any}
          type="dark"
        />
      </Link>
      {isSearchBar ? (
        <SearchBar toggleSearch={toggleSearchBar} fixed />
      ) : (
        <Categories>
          {CATEGORIES.slice(0, 10).map((category) => (
            <Category key={category.name}>
              <Link to={category.url}>
                {category.navicon && <Navicon height="20" src={category.navicon} />}
                {category.name}
              </Link>
            </Category>
          ))}
        </Categories>
      )}
      <Options>
        {!isSearchBar && (
          <button onClick={toggleSearchBar} data-analytics-name="nav-search-icon">
            <SearchIcon />
          </button>
        )}
        <Notifications />

        {!DISABLE_PREMIUM_FLAG_ACTIVE &&
          <Link to={mainDomainDashboard} data-analytics-name="nav-dashboard">
            <AccountIcon />
          </Link>
        }
        {/* ukrycie audio na mobile w górnej nawigacji */}
        {isDesktop && <AudioBrief type="hiddenNav" />}
      </Options>
    </NavWrap>
  );
};

const Navicon = styled("img", {
  height: '20px',
  marginRight: '6px',
  display: 'inline-block',
  float: 'left'
});

const scrollDown = keyframes({
  "0%": { transform: "translateY(-100%)" },
  "100%": { transform: "translateY(0)" },
});

const Categories = styled("div", {
  display: "none",
  flexDirection: "row",
  marginRight: "auto",
  alignItems: "center",
  "@bp4": {
    display: "flex",
  },
});

const Category = styled("span", {
  fontSize: 14,
  fontFamily: "$grotesk",
  fontWeight: "$6",
  /* lineHeight: "$18", */
  lineHeight: '20px',
  display: "inline-block",
  marginRight: 24,
  "& a": {
    color: defaultColors.grayscale0,
    textDecoration: "none",
    transition: "0.2s",
    "&:hover": {
      color: "$primary !important",
    },
  },
});

const Options = styled("div", {
  display: "flex",
  "& button, a": {
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    borderWidth: "0px",
    width: "48px",
    "@bp4": {
      width: "60px",
      borderLeft: "1px solid rgba(255, 255, 255, .1)",
    },
  },
});

const NavWrap = styled("div", {
  height: 52,
  width: "100%",
  backgroundColor: defaultColors.grayscale100,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  animation: `${scrollDown} 0.6s`,
  "@bp4": {
    height: 60,
    justifyContent: "flex-start",
  },
  ".navigation-top--logo": {
    marginLeft: "20px",
    "@bp4": {
      marginLeft: 0,
    }
  }
});

export default NavbarHidingDefault;
